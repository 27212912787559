<template>
  <div>
    <v-form>
      <h2 class="mb-5">Report Sheet - Identifying and Unknown Acid</h2>

      <p class="mb-3">a) What was the unknown code for your acid sample?</p>

      <calculation-input
        v-model="inputs.unknownCode"
        prepend-text="$\text{Code:}$"
        class="mb-3"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) Fill out the following table with the results from your experiment.</p>

      <v-simple-table class="mb-3 pl-6" style="max-width: 457px">
        <thead>
          <tr>
            <td><stemble-latex content="$\text{Property}$" /></td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Value}$" />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><stemble-latex content="$\text{Concentration of Acid Sample (M)}$" /></td>
            <td class="centered-input">
              <calculation-input v-model="inputs.concAcid" class="my-2" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{pK}_\text{a}\text{ of unknown acid}$" /></td>
            <td class="centered-input">
              <calculation-input v-model="inputs.pKa" class="my-2" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{K}_\text{a}\text{ of unknown acid}$" /></td>
            <td class="centered-input">
              <calculation-input v-model="inputs.Ka" class="my-2" :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>

        <!--        <tr>-->
        <!--          <td>Identity of unknown acid</td>-->
        <!--          <td class="centered-input">-->
        <!--            <v-select-->
        <!--              v-model="inputs.acidIdentity"-->
        <!--              style="width: 193px"-->
        <!--              dense-->
        <!--              :items="options"-->
        <!--              label="Select Option"-->
        <!--              item-text="text"-->
        <!--              item-value="value"-->
        <!--            >-->
        <!--              <template #item="{item}">-->
        <!--                <span class="no-text-transform" v-html="item.text" />-->
        <!--              </template>-->
        <!--              <template #selection="{item}">-->
        <!--                <span class="no-text-transform" v-html="item.text" />-->
        <!--              </template>-->
        <!--            </v-select>-->
        <!--          </td>-->
        <!--        </tr>-->
      </v-simple-table>

      <p class="mb-3">
        d) Based on the
        <stemble-latex content="$\text{K}_\text{a}$" />
        you just calculated and the master inventory, what is the identity of your acid? Justify
        your answer.
      </p>

      <v-textarea v-model="inputs.acidIdentityWritten" full-width outlined auto-grow />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemOleMiss116_Prelab10_Results',
  components: {STextarea, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        unknownCode: null,
        concAcid: null,
        pKa: null,
        Ka: null,
        acidIdentity: null,
        acidIdentityWritten: null,
      },
      options: [
        {
          text: 'Acetic Acid, $\\ce{CH3COOH}$',
          value: 'acetic',
        },
        {
          text: 'Carbonic Acid, $\\ce{H2CO3}',
          value: 'carbonic',
        },
        {
          text: 'Chloroacetic Acid, $\\ce{ClCH2COOH}',
          value: 'chloroacetic',
        },
        {
          text: 'Citric Acid, $\\ce{HOC(COOH)(CH2COOH)2}',
          value: 'citric',
        },
        {
          text: 'Hydrochloric acid, $\\ce{HCl}',
          value: 'hydrochloric',
        },
        {
          text: 'Hypochlorous acid, $\\ce{HOCl}',
          value: 'hypochlorous',
        },
        {
          text: 'Nitrous acid, $\\ce{HNO2}',
          value: 'nitrous',
        },
        {
          text: 'Sulfuric acid, $\\ce{H2SO4}',
          value: 'sulfuric',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
